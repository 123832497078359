@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {

    .margen-image-login{
      margin-bottom: -160px;
      margin-top: -200px;
    }

    .background-siavi{
      background: rgb(23,16,82);
      background: linear-gradient(172deg, rgba(23,16,82,1) 19%, rgba(36,186,222,1) 120%);
    }

    .row-td {
      word-wrap: break-word;
    }

    .alto-pdf {
      height:50vh;
    }

    .tooltip {
      position: relative;
      display: inline-block;
    }

    .tooltip .tooltiptext {
      visibility: hidden;
      width: 120px;
      background-color: #003580;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 50;
      bottom: 125%;
      left: 50%;
      margin-left: -60px;
      opacity: 0;
      transition: opacity 0.3s;
      font-size:13px;
    }

    .tooltip .tooltiptext::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }

    .tooltip:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }

    .altura-calendar{
      height:80vh;
    }

    .column-fixed{
      position: sticky;
      left:0;
    }

    .placeholder {
      color: white;
      opacity: 1; /* Firefox */
    }

    .altura-menus{
      overflow-y: auto;
      height: 80vh;
      overflow-x: hidden;
    }

    .tooltip-orden {
      position: relative;
    }

    .tooltip-orden .tooltiptext {
      visibility: hidden;
      width: 120px;
      background-color: #003580;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 50;
      bottom: 125%;
      left: 50%;
      margin-left: -60px;
      opacity: 0;
      transition: opacity 0.3s;
      font-size:13px;
    }

    .tooltip-orden .tooltiptext::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }

    .tooltip-orden:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }

    .altura-table{
      height: 500px;
    }
  }